import { graphql } from "gatsby";
import Map from "google-map-react";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { FullWidthColumnWrapper } from "../components/confines-wrappers";
import Footer from "../components/footer";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import {
  breadCrumbSchema,
  localBusinessSchema,
  organizationSchema
} from "../components/html-schema-snippets";
import Icon from "../components/icon";
import Layout from "../components/layout";
import Link from "../components/link";
import UserBadge from "../components/user-badge";
import MapStyles from "../config/google-maps-theme";
import getProfile from "../templates/utils/getProfile";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../theme/theme-helper";
import { IProfileFields } from "./about-us";

interface IProps {
  data: {
    userUser: {
      id: string;
      relationships: {
        profile__team_profile: IProfileFields[];
      };
    };
  };
}

declare global {
  // allow Tally to be used in window
  interface Tally { Tally: any; }
}

interface IMarkerProps {
  lat: number;
  lng: number;
}

/**
 * Contact page
 */
const ContactPage = ({ data }: IProps) => {
  const canonicalPath = "/contact";

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Contact",
      path: canonicalPath
    }
  ]);

  const schemaJson = [
    localBusinessSchema,
    organizationSchema,
    breadcrumbsSchemaData
  ];

  const profile = getProfile(data.userUser);
  const mugshot = profile.relationships && profile.relationships.field_mugshot;

  // Load Tally embeds
  useEffect(() => {
    if (window.Tally) {
      window.Tally.loadEmbeds();
    }
  }, []);

  return (
    <Layout>
      <>
        <HelmetWrapper
          title="Contact"
          description="Get in touch, we’d love to hear from you."
          canonicalPath={canonicalPath}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header role="banner">
          <Header
            initialLogo
            initialTheme="light"
          />
        </header>

        <main
          id="main-content"
          role="main"
        >
          <ContactPageContainer>
            <div className="contact-container">
              <div className="content-container">
                <FullWidthColumnWrapper className="contact-details-container">
                  <div className="contact-details-wrapper">
                    <h1 className="off-screen">
                      Contact
                    </h1>

                    <p className="heading">
                      Get in touch, we'd love to hear from you.
                    </p>

                    <div className="contact-badge-container">
                      <aside className="contact-badge-wrapper">
                        <UserBadge
                          headingTag="h2"
                          path={profile.path.alias}
                          fullName={profile.field_full_name}
                          job={profile.field_job_title}
                          summary={profile.field_team_micro_snippet}
                          cover={mugshot}
                        />
                      </aside>

                      <p className="contact-wrapper">
                        <span className="off-screen">
                          Call us on:
                          {" "}
                        </span>

                        <Link
                          className="telephone"
                          href="tel:+441173790309"
                          type="inline"
                        >
                          +44 (0)117 379 0309
                        </Link>

                        <br />

                        <span className="off-screen">
                          Or email us:
                          {" "}
                        </span>

                        <Link
                          className="email"
                          href="mailto:hello@gravitywell.co.uk"
                          type="inline"
                        >
                          hello@gravitywell.co.uk
                        </Link>
                      </p>
                    </div>
                  </div>

                  <div className="form-wrapper">
                    <Helmet>
                      <script src="https://tally.so/widgets/embed.js" />
                    </Helmet>

                    <iframe
                      data-tally-src="https://tally.so/embed/nWrPga?hideTitle=1&transparentBackground=1&dynamicHeight=1"
                      loading="lazy"
                      width="100%"
                      height="762"
                      frameBorder="0"
                      title="Gravitywell Website Contact Form"
                    />
                  </div>

                </FullWidthColumnWrapper>
              </div>
            </div>

            <div className="map-container">
              <Map
                center={{
                  lat: 51.45723,
                  lng: -2.596682
                }}
                zoom={15}
                options={{ styles: MapStyles }}
                bootstrapURLKeys={{ key: "AIzaSyCVx9SyYoac7D0g3YqsXCDBGeb6s2jlxlg" }}
                defaultCenter={{
                  lat: 51.45723,
                  lng: -2.596682
                }}
              >
                <Marker
                  lat={51.45723}
                  lng={-2.596682}
                >
                  <Icon
                    icon="lightbulb"
                    color="#f9f4f0"
                  />
                </Marker>
              </Map>
            </div>
          </ContactPageContainer>
        </main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export const query = graphql`
  query contactQuery {
    userUser(drupal_id: { eq: "15ba1b76-4182-4fc7-bb6a-d9c4a85063ff" }) {
      id
      relationships {
        profile__team_profile {
          ...getProfileData
        }
      }
    }
  }
`;

export default ContactPage;

const ContactPageContainer = styled.div`
  padding-top: 6.4rem;

  .contact-container {
    .contact-details-container {
      .contact-details-wrapper {
        .heading {
          padding-top: 0;
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
          font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_1"
    })};
          font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
          line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
        }

        .contact-badge-container {
          .contact-badge-wrapper {
            padding-top: 3.2rem;

            .summary {
              display: none;
            }
          }

          .contact-wrapper {
            padding-top: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
            text-align: center;
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
            font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
            line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "large"
    })};
          }
        }
      }

      .form-wrapper {
        padding-top: 10rem;
        padding-bottom: 0;

        > div {
          min-height: 120rem;

          iframe {
            margin: 0 auto;
            width: 100% !important;
          }
        }
      }
    }
  }

  .map-container {
    height: calc(100vh - 6.4rem);
    max-height: 68rem;
  }

  @media (min-width: 375px) {
    .contact-container {
      .contact-details-container {
        .contact-details-wrapper {
          .heading {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_2"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 568px) {
    .contact-container {
      .contact-details-container {
        .contact-details-wrapper {
          .heading {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
          }
        }
      }
    }
  }

  @media (min-width: 600px) {
    .contact-container {
      .contact-details-container {
        .contact-details-wrapper,
        .form-wrapper {
          margin: 0 auto;
          max-width: 60rem;
        }

        .contact-details-wrapper {
          .contact-badge-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-top: 6.2rem;

            .contact-badge-wrapper,
            .contact-wrapper {
              padding-top: 0;
            }

            .contact-wrapper {
              padding-bottom: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "large"
    })};
              text-align: left;
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_3"
    })};
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    padding-top: 8.2rem;

    .contact-container {
      .contact-details-container {
        .contact-details-wrapper {
          .heading {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }

          .contact-badge-container {
            .contact-wrapper {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
            }
          }
        }
      }
    }

    .map-container {
      height: calc(100vh - 8.2rem);
    }
  }

  @media (min-width: 1280px) {
    .contact-container {
      .contact-details-container {
        display: flex;
        flex-direction: row;

        .contact-details-wrapper,
        .form-wrapper {
          width: 50%;

          &:nth-child(odd) {
            padding-right: 4%;
          }

          &:nth-child(even) {
            padding-left: 4%;
          }
        }

        .contact-details-wrapper {
          max-width: none;

          .heading {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_3"
    })};
          }

          .contact-badge-container {
            padding-top: 8.8rem;

            .contact-wrapper {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_3"
    })};
            }
          }
        }

        .form-wrapper {
          padding-top: 1rem;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .contact-container {
      .contact-details-container {
        .contact-details-wrapper {
          .heading {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_4"
    })};
          }

          .contact-badge-container {
            .contact-wrapper {
              font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "title"
    })};
            }
          }
        }
      }
    }
  }

  @media (min-width: 1920px) {
    .contact-container {
      .contact-details-container {
        .contact-details-wrapper {
          .heading {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "display_5"
    })};
          }
        }
      }
    }
  }
`;

const Marker = styled<IMarkerProps, "div">("div")`
  background: ${({ theme }) => colourHelper({
    theme,
    variant: "brandColor"
  })};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  transform: translate(-50%, -50%);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
