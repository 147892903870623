const styles = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [ { color: "#6195a0" } ]
  },
  {
    featureType: "administrative.province",
    elementType: "geometry.stroke",
    stylers: [ { visibility: "off" } ]
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      { lightness: "76" },
      { saturation: "-62" },
      { gamma: "1" }
    ]
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [ { lightness: "-3" }, { gamma: "1.00" } ]
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "all",
    stylers: [ { visibility: "off" } ]
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [ { visibility: "off" } ]
  },
  {
    featureType: "poi.attraction",
    elementType: "geometry.fill",
    stylers: [ { visibility: "off" } ]
  },
  {
    featureType: "poi.business",
    elementType: "geometry.fill",
    stylers: [
      { visibility: "on" },
      { hue: "#00c2ff" },
      { lightness: "23" }
    ]
  },
  {
    featureType: "poi.government",
    elementType: "geometry.fill",
    stylers: [ { visibility: "off" }, { color: "#d70707" } ]
  },
  {
    featureType: "poi.medical",
    elementType: "geometry.fill",
    stylers: [
      { visibility: "on" },
      { hue: "#00ebff" },
      { saturation: "-29" },
      { lightness: "43" }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [ { color: "#bae5ce" }, { visibility: "on" } ]
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      { saturation: -100 },
      { lightness: 45 },
      { visibility: "simplified" }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [ { visibility: "simplified" } ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [ { color: "#fac9a9" }, { visibility: "simplified" } ]
  },
  {
    featureType: "road.highway",
    elementType: "labels.text",
    stylers: [ { color: "#4e4e4e" } ]
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [ { color: "#787878" } ]
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [ { visibility: "off" } ]
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [ { visibility: "simplified" } ]
  },
  {
    featureType: "transit.station.airport",
    elementType: "labels.icon",
    stylers: [
      { hue: "#0a00ff" },
      { saturation: "-77" },
      { gamma: "0.57" },
      { lightness: "0" }
    ]
  },
  {
    featureType: "transit.station.rail",
    elementType: "labels.text.fill",
    stylers: [ { color: "#43321e" } ]
  },
  {
    featureType: "transit.station.rail",
    elementType: "labels.icon",
    stylers: [
      { hue: "#ff6c00" },
      { lightness: "4" },
      { gamma: "0.75" },
      { saturation: "-68" }
    ]
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [ { color: "#eaf6f8" }, { visibility: "on" } ]
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [ { color: "#c7eced" } ]
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      { lightness: "-49" },
      { saturation: "-53" },
      { gamma: "0.79" }
    ]
  }
];

export default styles;
